import React, { useEffect, useRef, useState } from "react";

import "./App.css";
import Chatbot from "./Chatbot";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import fuse from "fuse.js";
import Fuse from "fuse.js";
import data from "./Chatbot/data";

const mic = require("./mic.png");
const micPlaying = require("./mic-playing.png");
const play = require("./play.png");
function App() {
  const [current, setCurrent] = useState("idle");
  const [isClicked, setClicked] = useState(false);
  const vidRef = useRef<HTMLVideoElement>();

  const options = {
    includeScore: true,
    // Search in `author` and in `tags` array
    keys: ["utterance"],
  };

  const fuse = new Fuse(data, options);

  const playGreet = () => {
    setClicked(true);
    setCurrent("greet");
  };

  const { transcript, listening, resetTranscript } = useSpeechRecognition();

  const onMicClick = () => {
    SpeechRecognition.startListening({ continuous: false, language: "en-IN" });
  };

  const search = () => {
    SpeechRecognition.stopListening();
    if (transcript.trim().length < 1) return;

    setTimeout(() => {
      const result = fuse.search(transcript);

      if (result.length > 0) {
        setCurrent(result[0].item.id);
      } else {
        setCurrent("fallback");
      }
    }, 1200);

    resetTranscript();
  };

  useEffect(() => {
    if (!listening && transcript.trim().length > 0) search();
    else {
      setCurrent("idle");
    }
  }, [listening]);

  if (true)
    return (
      <div
        className="App"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "30px 40px",
          paddingBottom: "0px",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: "0px",
            width: "fit-content",
          }}
        >
          <video
            id="vid"
            ref={vidRef}
            key={current}
            width="450px"
            muted={current == "idle"}
            autoPlay={true}
            loop={current === "idle"}
            onEnded={() => setCurrent("idle")}
            style={{ display: "block" }}
          >
            <source src={`./videos/${current}.webm`} />
          </video>

          <img
            className="send-btn"
            src={isClicked ? (listening ? micPlaying : mic) : play}
            alt=""
            style={{
              position: "absolute",
              bottom: "10px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "50px",
              cursor: "pointer",
            }}
            onClick={() => {
              if (isClicked) {
                if (listening) {
                  // search();
                } else onMicClick();
              } else playGreet();
            }}
          />
        </div>
      </div>
    );

  return (
    <div
      className="App"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "30px 40px",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "450px",

          borderRadius: "20px",
        }}
      >
        <Chatbot current={current} setCurrent={setCurrent} />
      </div>
      <div style={{ width: "50%", position: "relative" }}>
        <video
          id="vid"
          ref={vidRef}
          key={current}
          width="100%"
          muted={current == "idle"}
          autoPlay={true}
          loop={current === "idle"}
          onEnded={() => setCurrent("idle")}
        >
          <source src={`./videos/${current}.webm`} />
        </video>
      </div>
    </div>
  );
}

export default App;
