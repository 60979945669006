export const answers = [
  {
    id: "greeting",
    utterance:
      "Welcome to Project Voice. I am Bradley Metrock. It's chief executive officer. How can i help you?",
  },

  {
    id: "fallback",
    utterance:
      "Sorry, I prefer not to respond to this as it falls out of my expertise",
  },

  {
    id: "what",
    utterance:
      "Project Voice is a program which aims to accelerate adoption of conversational AI, and by extension, voice technology, through content, consulting and community. We organise podcasts and events to regularly speak with a who's-who of conversational AI and voice AI executives.",
  },
  {
    id: "podcast",
    utterance:
      'We produce the influential podcast "This Week In Voice" which completed Season 7 this fall and returns with Season 8 in early 2023.',
  },
  {
    id: "when",
    utterance:
      "Project voice 2023 is going to be held in Chattanooga, Tennessee from April 24 to April 26, 2023.",
  },
  {
    id: "con-ai",
    utterance:
      "Conversational AI is a type of artificial intelligence that enables consumers to interact with computer applications the way they would with other humans. This includes technologies, like chatbots or virtual agents, like myself.",
  },
  {
    id: "corover",
    utterance:
      "I am built by CoRover, a conversational AI company that makes virtual agents and chatbots across multiple languages, channels and formats like text, voice and video.",
  },
];

const data = [
  {
    id: "what",
    utterance: "what is project voice",
  },
  {
    id: "podcast",
    utterance: "what is your podcast called?",
  },
  {
    id: "when",
    utterance: "when and where Project Voice is going to happen?",
  },
  {
    id: "con-ai",
    utterance: "what is conversational ai",
  },
  {
    id: "corover",
    utterance: "who created this virtual agent?",
  },

  // Welcome to Project Voice. I am Bradley Metrock. It's chief executive officer. How can i help
];

export default data;
