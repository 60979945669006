import React, { useEffect, useRef, useState } from "react";
import Bubble from "./Bubble";
import "./styles.css";
import Fuse from "fuse.js";
import data, { answers } from "./data";

const send = require("./send.png");

const Chatbot = ({ current, setCurrent }) => {
  const options = {
    includeScore: true,
    // Search in `author` and in `tags` array
    keys: ["utterance"],
  };
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");

  const fuse = new Fuse(data, options);

  //   useEffect(() => {
  //     setTimeout(() => {
  //       let botM = {
  //         from: "bot",
  //         text: getAnswer("greet"),
  //       };
  //       setMessages((prev) => [...prev, botM]);
  //     }, 2000);
  //   }, []);

  const playVideo = (vid) => {
    // setCurrent(vid);
    // document.getElementById("vid").onplay();
  };

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const mesRef = useRef<HTMLDivElement>();

  const scrollToBottom = () => {};

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        borderRadius: "20px",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      }}
      className="chatbot"
    >
      <div className="header">
        <span>Ask</span>
        <p>Bradley</p>
      </div>
      <div className="messages">
        {messages.map((message) => (
          <Bubble message={message} />
        ))}
      </div>
      <div className="sender">
        <input
          value={input}
          type="text"
          //   onClick={search}
          onChange={handleChange}
          placeholder={"Ask your query here..."}
        />
        {/* <img className="send-btn" src={send} onClick={search} /> */}
      </div>
    </div>
  );
};

export default Chatbot;
